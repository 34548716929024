<div class="shipment-details-dialog ups-dialog">
    <div class="dialog-header">
        <h2 mat-dialog-title>{{'ShipmentDetails'|translate}}</h2>
        <button type="button" mat-raised-button class="icon-button close-button" mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-dialog-content>
        <div class="actions">
            <upsc-ship-details-actions class=""
                                       [carrier]="carrier"
                                       [shipment]="shipmentdetails"
                                       [showReship]="shouldShowButtonReship(shipmentdetails) && !isReturnService"
                                       [showReprintLabel]="shouldShowButtonPrintLabel(shipmentdetails)"
                                       [showEmailLabel]="shouldShowButtonEmailLabel(shipmentdetails)"
                                       [showDownloadLabel]="shouldShowButtonDownloadLabel(shipmentdetails)"
                                       [showReprintCommercialInvoice]="shouldShowButtonPrintCommercialInvoice(shipmentdetails)"
                                       [showPrintNAFTAForm]="isNAFTACertificate"
                                       [showPrintShipmentDetails]="shouldShowButtonPrintShipmentDetails(shipmentdetails)"
                                       [showViewPendingShipments]="false"
                                       [showVoidShipment]="shouldShowButtonVoid(shipmentdetails)"
                                       [showPOD]="shouldShowButtonPOD(shipmentdetails)"
                                       [isAllowShipmentEdit]="isAllowShipmentEdit && !isReturnService"
                                       (fileClaimClicked)="shipDetails.fileClaim()"
                                       (reshipClicked)="shipDetails.reship()"
                                       (reprintLabelClicked)="shipDetails.printLabel()"
                                       (emailLabelClicked)="openShippingLabel(shipmentdetails?.ShipmentId)"
                                       (downloadLabelClicked)="shipDetails.downloadLabel()"
                                       (reprintCommercialInvoiceClicked)="printCommercialInvoice(shipmentdetails?.ShipmentId)"
                                       (printNAFTAFormClicked)="printNAFTAForm($event)"
                                       (printShipmentDetailsClicked)="printShipmentDetails(null)"
                                       (viewPendingShipmentClicked)="viewPendingShipments()"
                                       (voidShipmentClicked)="voidConfirmation(shipmentdetails?.ShipmentId)"
                                       (editShipmentClicked)="shipDetails.showEditConfirmationDialog()"
                                       (PODClicked)="shipDetails.downloadPOD()">
                                    </upsc-ship-details-actions>
        </div>

        <!-- <div class="actions hide-me">
            <button mat-raised-button
                    type="button"
                    *ngIf="shouldShowButtonEmailLabel(shipmentdetails)"
                    (click)="openShippingLabel(shipmentdetails?.ShipmentId)">{{'EmailLabel'|translate}}
            </button>
            <button mat-raised-button
                    type="button"
                    *ngIf="shouldShowButtonPrintLabel(shipmentdetails)"
                    (click)="shipDetails.printLabel()">{{'Reprint'|translate}}
            </button>
            <button mat-raised-button
                    type="button"
                    *ngIf="shouldShowButtonDownloadLabel(shipmentdetails)"
                    (click)="shipDetails.downloadLabel()">{{'DownloadLabel'|translate}}
            </button>
            <div class="spinner-container">
                <button mat-raised-button
                        type="button"
                        class="alternative-button"
                        (click)="printCommercialInvoice(shipmentdetails?.ShipmentId)"
                        *ngIf="shouldShowButtonPrintCommercialInvoice(shipmentdetails)">
                    {{'ReprintCommercialInvoice'|translate}}
                </button>
                <upsc-element-blocker *ngIf="isDownloadingCommercialInvoice"></upsc-element-blocker>
            </div>
            <button type="button"
                    mat-raised-button
                    class="page-action alternative-button"
                    *ngIf="shouldShowButtonPrintShipmentDetails(shipmentdetails)"
                    (click)="printShipmentDetails($event)">
                {{ 'PrintShipmentDetails' | translate }}
            </button>
            <button mat-raised-button
                    type="button"
                    class="alternative-button"
                    (click)="voidConfirmation(shipmentdetails?.ShipmentId)"
                    *ngIf="shouldShowButtonVoid(shipmentdetails)">
                {{'Void'|translate}}
            </button>
        </div> -->

        <upsc-ship-details #shipDetails
                           [containerDialogRef]="selfDialogRef"
                           [shipmentPackage]="shipmentdetails"
                           [shipmentType]="'web'"
                           [shouldPrintLabel]="false"
                           (shipmentVoiding)="onShipmentVoiding($event)"></upsc-ship-details>
        <!--<ng-container [ngTemplateOutlet]="labelForm" *ngIf="false"></ng-container>-->

        <upsc-element-blocker #dialogSpinner [class.hide-me]="!isShipmentDetailsLoading"></upsc-element-blocker>
    </mat-dialog-content>
</div>
